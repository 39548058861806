import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatPaginator,
         MatSort, MatDialog } from '@angular/material';

import { FormGroup } from '@angular/forms';
//import { CurrentStock } from '../../../interface/currentstock';
import { Order } from '../../../interface/order';
import { OrderItem } from '../../../interface/orderitem';
import { Sale } from '../../../interface/sale';
import { RepositoryService } from './../../../repository.service';
import { ProgressService } from './../../../progress.service';

@Component({
  selector: 'app-checkout-dialog',
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.css']
})
export class CheckoutDialogComponent implements OnInit {
  _data: any;
 

  
  constructor(public dialogRef: MatDialogRef<CheckoutDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Order,
              private repoService: RepositoryService,
              public progressService: ProgressService) 
              {
                  
              }

  ngOnInit() {    
    //this.checkCurrentStock(this._data.id);    
  }

    
    

  closeDialog(){
    //alert('close');
    this.dialogRef.close(this._data.id);
  }
  /*doAction(){
    this.dialogRef.close({event:this.action, data:this.local_data});
  }
 
  closeDialog(){
    this.dialogRef.close({event:'Cancel'});
  }*/

  
}

  




